<template>
  <v-container>
    <TosListVuetify serviceName="block" entityName="blocks" :headers="headers">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('Codice')" v-model="slotProps.data.codeLike" />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:customButtons="slotProps">
        <v-btn icon title="Risolvi"  @click="forceResolve(slotProps.data)" :disabled="slotProps.data.resolved || slotProps.data.dependsOnCode">
            <v-icon color="primary" medium> mdi-lock-off</v-icon>
          </v-btn>
      </template>
      <template #item.resolved="slotProps">
        <div>
          <v-icon v-if="slotProps.data.resolved" color="primary" medium>mdi-check</v-icon>
        </div>
      </template>
    
      <template #editF="slotProps">
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabel('Codice')" v-model="slotProps.data.code" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Dipende da Codice')" v-model="slotProps.data.dependsOnCode" />
          </v-col>
          <v-col cols="2">
            <v-select :label="tLabel('Categoria')" v-model="slotProps.data.category" :items="category" :enumsMap="categoryMap" />
          </v-col>
          <v-col cols="2">
              <v-select :label="tLabel('Direzione')" v-model="slotProps.data.direction" :items="directions" :enumsMap="directionsMap" />
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field :label="tLabel('Note')" v-model="slotProps.data.remarks" />
          </v-col>
        </v-row>
      </template>
    </TosListVuetify>

  </v-container>

</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Block History",
  data() {
    return {
      condition: {},
      category: null,
      directions: null,
      directionsMap: {},
      categoryMap: {},
      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "code" },
        { text: "Type", value: "blockType" },
        { text: "Key", value: "blockKey" },
        { text: "Direzione", value: "direction" },
        { text: "Rule", value: "blockRule.name" },
        { text: "Categoria", value: "blockRule.category" },
        { text: "Sottocategoria", value: "blockRule.subCategory" },
        { text: "Depends on", value: "dependsOnCode" },
        { text: "Risolto", value: "resolved" },
        //{ text: "Manuale", value: "manual" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify,
  },

  created() {},
  async mounted() {
    await this.loadCombo();
  },
  methods: {
    validate(item) {
      let errors = {};
      return errors;
    },
    async loadCombo() {
      const all = await this.$api.yardData.getLookupsBlocks();
      this.directions = this.mapList(this.directionsMap, all.directions);
      this.category = this.mapList(this.categoryMap, all.category);
      console.log("Category_ ", this.category)
    },

    mapList(map, list) {
      list.forEach((element) => {
        map[element.value] = element.text;
      });
      return list;
    },
    async forceResolve(data){
      data=await this.$api.yardData.forceResolveBlock(data.id);
    },
  },
  mixins: [FormatsMixins],
};
</script>
